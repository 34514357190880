export const SITE_NAV_LINKS = [
  { path: "/", title: "Home", enabled: true },
  { path: "/about", title: "About", enabled: true },
  { path: "/projects", title: "Projects", enabled: false },
  { path: "/blog", title: "Blog", enabled: true },
  { path: "/contact", title: "Contact", enabled: true },
  { path: "/books", title: "Books", enabled: false },
]

export const EXTERNAL_NAV_LINKS = [
  { path: "https://twitter.com/kntreadway", icon: "twitter", id: "twitter" },
  { path: "https://github.com/knt", icon: "github", id: "github" },
  {
    path: "https://www.linkedin.com/in/kntreadway",
    icon: "linkedin",
    id: "linkedin",
  },
  {
    path: "http://instagram.com/kntreadway",
    icon: "instagram",
    id: "instagram",
  },
]
