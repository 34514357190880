import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import * as headerStyles from "./header.module.scss"
import { SITE_NAV_LINKS, EXTERNAL_NAV_LINKS } from "../config/header-config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fab)

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header className={headerStyles.header}>
      <h1>
        <Link to="/" className={headerStyles.title}>
          {data.site.siteMetadata.title}
        </Link>
      </h1>
      <nav className={headerStyles.navLinks}>
        <ul className={headerStyles.siteNavList}>
          {SITE_NAV_LINKS.filter(navLink => navLink.enabled).map(navLink => (
            <li key={navLink.title}>
              <Link
                className={headerStyles.siteNavItem}
                activeClassName={headerStyles.active}
                to={navLink.path}
              >
                {navLink.title}
              </Link>
            </li>
          ))}
        </ul>

        <ul className={headerStyles.externalLinkList}>
          {EXTERNAL_NAV_LINKS.map(navLink => (
            <li className={headerStyles.externalLinkItem} key={navLink.id}>
              <a href={navLink.path}>
                <FontAwesomeIcon icon={["fab", navLink.icon]} size="lg" />
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
